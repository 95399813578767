export const RequestPost = async (args, url, auth) => {
    const controller = new window.AbortController();
    const signal = controller.signal;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url = "https://lifequery.org/" + url;

    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            auth
        },
        signal: signal,
        body: JSON.stringify(args)
    });
    const content = await rawResponse.json();
    return content;
}


export const RequestGet = async (url) => {
    const controller = new window.AbortController();
    const signal = controller.signal;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url = "https://lifequery.org/" + url;

    return await fetch(url, { method: 'GET', signal: signal })
        .then(response => response.arrayBuffer())
        .then(buffer => {
            let decoder = new TextDecoder("utf-8");
            let text = decoder.decode(buffer);
            return JSON.parse(text);
        });


}


export class Request {
    constructor() {
        this.state = { loading: false };
        this.Aborter();
    }

    Aborter = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;
    }

    Run = async (url, method = 'GET', args, authtoken = "") => {
        this.Aborter();
        this.url = url;
        this.method = method;
        this.args = args || {};
        if(this.url.toLowerCase().indexOf("http") === -1 && this.url.substr(0,1) !== "/" && this.url.substr(0,2) !== "./" ) this.url = "https://lifequery.org/" + this.url;

        this.state.loading = true;
        if (this.method.toLocaleUpperCase() === "POST") {
            return await fetch(this.url, {
                method: this.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(response => {
                this.state.loading = false;
                let r = response.json();
                try {
                    if(r.status===401) window.location.href="/login";
                } catch(e) { }
                return r;
            });
        } else {
            return await fetch(this.url, {
                method: 'GET', headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': authtoken
                }, signal: this.signal
            })
                .then(response => response.arrayBuffer())
                .then(buffer => {
                    let decoder = new TextDecoder("iso-8859-1");
                    let text = decoder.decode(buffer);
                    this.state.loading = false;
                    let r = JSON.parse(text);
                    try {
                        if(r.status===401) window.location.href="/login";
                    } catch(e) { }
                    return r;
                });
        }
    }

    Abort = () => {
        console.log("Aborting ", this.state.loading);
        //if (this.state.loading) {
        this.controller.abort();
        this.state.loading = false;
        //}
    }

    State = () => {
        return this.state;
    }
}


export class RequestRetry {
    constructor() {
        this.state = { loading: false };
        this.Aborter();
        this.try = 5;
    }

    Aborter = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;
    }

    Run = async (url, method = 'GET', args) => {
        this.Aborter();
        this.url = url;
        this.method = method;
        this.args = args || {};
        if(this.url.toLowerCase().indexOf("http") === -1 && this.url.substr(0,1) !== "/" && this.url.substr(0,2) !== "./" ) this.url = "https://lifequery.org/" + this.url;

        this.state.loading = true;
        if (this.method.toLocaleUpperCase() === "POST") {
            return await fetch(this.url, {
                method: this.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                signal: this.signal,
                body: JSON.stringify(this.args)
            }).then(response => {
                this.try = 5;
                if (this.state.loading) return response.json();
                this.state.loading = false;
            }).catch(() => {
                this.try--;
                if (this.try > 0) {
                    if (this.state.loading) return this.Run(this.url, this.method, this.args);
                } else {
                    return null;
                }
            });
        } else {
            return await fetch(url, { method: 'GET', signal: this.signal })
                .then(response => response.arrayBuffer())
                .then(buffer => {
                    let decoder = new TextDecoder("iso-8859-1");
                    let text = decoder.decode(buffer);
                    if (this.state.loading) return JSON.parse(text);
                    this.state.loading = false;
                }).catch(() => {
                    this.try--;
                    if (this.try > 0) {
                        if (this.state.loading) return this.Run(this.url, this.method, this.args);
                    } else {
                        return null;
                    }
                });
        }
    }

    Abort = () => {
        console.log("Aborting ", this.state.loading);
        this.state.loading = false;
        this.controller.abort();
    }

    State = () => {
        return this.state;
    }
}


/*export const RequestPost = async (args, url) => {
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(args)
    });
    const content = await rawResponse.json();
    return content;
}


export const RequestGet = async (url) => {
    return await fetch(url)
        .then(response => response.arrayBuffer())
        .then(buffer => {
            let decoder = new TextDecoder("utf-8");
            let text = decoder.decode(buffer);
            return JSON.parse(text);
        });
}*/