import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import logo from './logo.svg';
import Reativar from './components/Login/Reativar';
import { SSL } from './scripts/SSL';
import './App.css';





function App() {
  const [pageLoading, setPageLoading] = useState(true);
  const [complete, setComplete] = useState(false);


  useEffect(() => {
    //SSL.ToHTTPS();
  }, []);


  const HandlerSuccess = () => {
    setComplete(true);
  }





  return (
    <div className="App">
      <br />
      <div className="painel">
        <div className="logo">
            <img src="/img/logo.png" />
        </div>
        {complete ? (<div>
          <br /><br /><center><span className="topTitle"><h2>Compra Concluída!</h2></span></center><br /><br /><br /><br />
        </div>) : (<Reativar onSuccess={HandlerSuccess} Title="Renovar conta"/>)}
        
      </div>
      <br />
      <center>
            Surveillance A.I. &copy; 2022 - Recomendado com Google Chrome - <i className="fa fa-envelope-o"></i> <a>contato@lifequery.org</a> - <i className="fa fa-whatsapp"></i> <a>(12) 98122-1043</a>
        </center>
      <br />
    </div>
  );
}

export default App;
